import { RESET_ADMIN_USERS, RESET_KEYWORD_ENGINE } from 'constants/action_types'
import { RESET_KEYWORD_LISTS } from './keywords/keyword_lists'
import { RESET_PRODUCT_COSTS } from './product_costs'
import { RESET_PRODUCT_DATABASE } from './product_database'
import { RESET_PRODUCTS } from './product'
import { RESET_PROFIT_OVERVIEW } from './sales_analytics/profit_overview'
import { RESET_PROFIT_AND_LOSS } from './sales_analytics/profit_and_loss'
import { RESET_EXPENSES } from './sales_analytics/expenses'
import { RESET_INVENTORY_FORECAST } from './inventory/inventory_forecast'
import { RESET_PRODUCT_TRACKER } from './tracker/tracking'
import { RESET_ALERTS } from './alerts'
import { RESET_ACADEMY } from './academy'
import { RESET_API_KEY } from './api_keys'

export const RESET_PAGES = [
  RESET_PRODUCT_TRACKER,
  RESET_KEYWORD_ENGINE,
  RESET_KEYWORD_LISTS,
  RESET_PRODUCT_COSTS,
  RESET_PRODUCT_DATABASE,
  RESET_PRODUCTS,
  RESET_PROFIT_OVERVIEW,
  RESET_PROFIT_AND_LOSS,
  RESET_EXPENSES,
  RESET_INVENTORY_FORECAST,
  RESET_ADMIN_USERS,
  RESET_ACADEMY,
  RESET_ALERTS,
  RESET_API_KEY
]
