export const PENDING_PRODUCT_ALERTS_ADD_REMOVE_ACTION =
  'PENDING_PRODUCT_ALERTS_ADD_REMOVE_ACTION'
export const PENDING_PRODUCT_ALERTS_ADD_REMOVE_BULK_ACTION =
  'PENDING_PRODUCT_ALERTS_ADD_REMOVE_BULK_ACTION'
export const RESET_PRODUCT_ALERTS_ADD_REMOVE_ACTION =
  'RESET_PRODUCT_ALERTS_ADD_REMOVE_ACTION'
export const RESET_PRODUCT_ALERTS_ADD_REMOVE_BULK_ACTION =
  'RESET_PRODUCT_ALERTS_ADD_REMOVE_BULK_ACTION'
export const COMPLETED_ADD_PRODUCT_ALERTS_ACTION =
  'COMPLETED_ADD_PRODUCT_ALERTS_ACTION'
export const COMPLETED_ADD_PRODUCT_ALERTS_BULK_ACTION =
  'COMPLETED_ADD_PRODUCT_ALERTS_BULK_ACTION'
export const COMPLETED_REMOVE_PRODUCT_ALERTS_ACTION =
  'COMPLETED_REMOVE_PRODUCT_ALERTS_ACTION'
export const PENDING_PRODUCT_ALERTS_ACTION = 'PENDING_PRODUCT_ALERTS_ACTION'
export const RESET_PRODUCT_ALERTS_ACTION = 'RESET_PRODUCT_ALERTS_ACTION'
export const SET_PRODUCT_ALERTS_DATA = 'SET_PRODUCT_ALERTS_DATA'
