export const PENDING_SEGMENT_BUILDER_ACTION = 'PENDING_SEGMENT_BUILDER_ACTION'
export const COMPLETED_SEGMENT_BUILDER_ACTION =
  'COMPLETED_SEGMENT_BUILDER_ACTION'
export const SET_SEGMENT_BUILDER_DATA = 'SET_SEGMENT_BUILDER_DATA'
export const RESET_SEGMENT_BUILDER_DATA = 'RESET_SEGMENT_BUILDER_DATA'
export const RESET_SEGMENT_BUILDER_ACTION = 'RESET_SEGMENT_BUILDER_ACTION'
export const SET_ASINS_PARAMETER_LIMIT = 'SET_ASINS_PARAMETER_LIMIT'
export const SET_BRANDS_PARAMETER_LIMIT = 'SET_BRANDS_PARAMETER_LIMIT'
export const SET_KEYWORDS_PARAMETER_LIMIT = 'SET_KEYWORDS_PARAMETER_LIMIT'
export const SET_EXCLUDE_ASINS_FILTER_LIMIT = 'SET_EXCLUDE_ASINS_FILTER_LIMIT'
export const SET_EXCLUDE_KEYWORDS_FILTER_LIMIT =
  'SET_EXCLUDE_KEYWORDS_FILTER_LIMIT'
