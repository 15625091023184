export const INVENTORY_FORECAST_LOAD_PENDING_STATUS =
  'INVENTORY_FORECAST_LOAD_PENDING_STATUS'
export const INVENTORY_FORECAST_LOAD_COMPLETE =
  'INVENTORY_FORECAST_LOAD_COMPLETE'
export const INVENTORY_FORECAST_LOAD_COMPLETE_STATUS =
  'INVENTORY_FORECAST_LOAD_COMPLETE_STATUS'
export const INVENTORY_FORECAST_PER_PAGE_CHANGED =
  'INVENTORY_FORECAST_PER_PAGE_CHANGED'
export const INVENTORY_FORECAST_COLUMN_TOGGLE =
  'INVENTORY_FORECAST_COLUMN_TOGGLE'
export const INVENTORY_FORECAST_ALL_COLUMNS_TOGGLE =
  'INVENTORY_FORECAST_ALL_COLUMNS_TOGGLE'
export const INVENTORY_FORECAST_SELECT_SALES_HISTORY =
  'INVENTORY_FORECAST_SELECT_SALES_HISTORY'
export const INVENTORY_FORECAST_FILTER_MODAL_TOGGLE =
  'INVENTORY_FORECAST_FILTER_MODAL_TOGGLE'
export const INVENTORY_FORECAST_HISTORICAL_TABLE_DATA_PENDING_STATUS =
  'INVENTORY_FORECAST_HISTORICAL_TABLE_DATA_PENDING_STATUS'
export const INVENTORY_FORECAST_HISTORICAL_TABLE_DATA_COMPLETE =
  'INVENTORY_FORECAST_HISTORICAL_TABLE_DATA_COMPLETE'
export const INVENTORY_FORECAST_HISTORICAL_TABLE_DATA_COMPLETE_STATUS =
  'INVENTORY_FORECAST_HISTORICAL_TABLE_DATA_COMPLETE_STATUS'
export const INVENTORY_FORECAST_SET_REPLENISHMENT_INFORMATION =
  'INVENTORY_FORECAST_SET_REPLENISHMENT_INFORMATION'
export const INVENTORY_REPLENISHMENT_PENDING_STATUS =
  'INVENTORY_REPLENISHMENT_PENDING_STATUS'
export const INVENTORY_REPLENISHMENT_COMPLETE_STATUS =
  'INVENTORY_REPLENISHMENT_COMPLETE_STATUS'
export const INVENTORY_FORECAST_FILTERS_CHANGED =
  'INVENTORY_FORECAST_FILTERS_CHANGED'
export const RESET_INVENTORY_FORECAST = 'RESET_INVENTORY_FORECAST'
export const INVENTORY_FORECAST_SET_MODAL = 'INVENTORY_FORECAST_SET_MODAL'
export const INVENTORY_SET_SELECTED_PRODUCT_COST =
  'INVENTORY_SET_SELECTED_PRODUCT_COST'
export const INVENTORY_FORECAST_UPDATE_PRODUCT_FORECAST =
  'INVENTORY_FORECAST_UPDATE_PRODUCT_FORECAST'
