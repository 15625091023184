export const ADMIN_USER_ORDER_COUNT_LOAD_PENDING_STATUS =
  'ADMIN_USER_ORDER_COUNT_LOAD_PENDING_STATUS'
export const ADMIN_USER_ORDER_COUNT_LOAD_COMPLETE =
  'ADMIN_USER_ORDER_COUNT_LOAD_COMPLETE'
export const ADMIN_USER_ORDER_COUNT_LOAD_COMPLETE_STATUS =
  'ADMIN_USER_ORDER_COUNT_LOAD_COMPLETE_STATUS'

export const UPDATE_ADMIN_USER_MERGE_ACCOUNTS_MODAL_DATA =
  'UPDATE_ADMIN_USER_MERGE_ACCOUNTS_MODAL_DATA'

export const UPDATE_ADMIN_USER_REFETCH_ORDERS_MODAL_DATA =
  'UPDATE_ADMIN_USER_REFETCH_ORDERS_MODAL_DATA'

export const UPDATE_ADMIN_USER_REFETCH_RESHIPPINGS_MODAL_DATA =
  'UPDATE_ADMIN_USER_REFETCH_RESHIPPINGS_MODAL_DATA'

export const UPDATE_ADMIN_SELLER_RESET_SYNC_MODAL_DATA =
  'UPDATE_ADMIN_SELLER_RESET_SYNC_MODAL_DATA'

export const SET_CAN_CLOSE_USER_NOTE = 'SET_CAN_CLOSE_USER_NOTE'
export const SET_ADMIN_USER_API_KEYS = 'SET_ADMIN_USER_API_KEYS'
export const SET_ADMIN_USER_API_REQUEST_COUNTS =
  'SET_ADMIN_USER_API_REQUEST_COUNTS'
export const PENDING_ADMIN_REVOKE_API_KEY = 'PENDING_ADMIN_REVOKE_API_KEY'
export const COMPLETED_ADMIN_REVOKE_API_KEY = 'COMPLETED_ADMIN_REVOKE_API_KEY'
export const SUCCESSFUL_ADMIN_REVOKE_API_KEY = 'SUCCESSFUL_ADMIN_REVOKE_API_KEY'
export const RESET_ADMIN_USERS = 'RESET_ADMIN_USERS'
