export const PENDING_SEGMENT_ACTION = 'PENDING_SEGMENT_ACTION'
export const RESET_SEGMENT_ACTION = 'RESET_SEGMENT_ACTION'
export const SET_SEGMENTS_DATA = 'SET_SEGMENTS_DATA'
export const SET_SEGMENT_FILTER = 'SET_SEGMENT_FILTER'
export const RESET_SEGMENT_BUILDER = 'RESET_SEGMENT_BUILDER'
export const UPDATE_SEGMENTS_DATA = 'UPDATE_SEGMENTS_DATA'
export const PENDING_CATEGORY_NAMES_ACTION = 'PENDING_CATEGORY_NAMES_ACTION'
export const SET_CATEGORY_NAMES_DATA = 'SET_CATEGORY_NAMES_DATA'
export const RESET_CATEGORY_NAMES_ACTION = 'RESET_CATEGORY_NAMES_ACTION'
export const SET_SELECTED_SEGMENTS = 'SET_SELECTED_SEGMENTS'
export const SET_BULK_UPDATE_SEGMENTS_VALUES = 'SET_BULK_UPDATE_SEGMENTS_VALUES'
