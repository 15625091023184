export const TOGGLE_SALES_ANALYTICS_CALENDAR_MODAL =
  'TOGGLE_SALES_ANALYTICS_CALENDAR_MODAL'

export const SALES_ANALYTICS_PENDING_STATE = 'SALES_ANALYTICS_PENDING_STATE'
export const SALES_ANALYTICS_COMPLETED_STATE = 'SALES_ANALYTICS_COMPLETED_STATE'

export const SALES_ANALYTICS_LOAD_DATA = 'SALES_ANALYTICS_LOAD_DATA'
export const SALES_ANALYTICS_RESET_DATA = 'SALES_ANALYTICS_RESET_DATA'

export const SET_DATE_RANGE = 'SET_DATE_RANGE'
export const SET_COMPARISON_DATE_RANGE = 'SET_COMPARISON_DATE_RANGE'
