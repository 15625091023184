export const PRODUCT_COSTS_LOAD_PRODUCTS_PENDING_STATUS =
  'PRODUCT_COSTS_LOAD_PRODUCTS_PENDING_STATUS'
export const PRODUCT_COSTS_LOAD_PRODUCTS_COMPLETE =
  'PRODUCT_COSTS_LOAD_PRODUCTS_COMPLETE'
export const PRODUCT_COSTS_LOAD_PRODUCTS_COMPLETE_STATUS =
  'PRODUCT_COSTS_LOAD_PRODUCTS_COMPLETE_STATUS'

export const PRODUCT_COSTS_LOAD_EXPORT_PENDING_STATUS =
  'PRODUCT_COSTS_LOAD_EXPORT_PENDING_STATUS'
export const PRODUCT_COSTS_LOAD_EXPORT_COMPLETE_STATUS =
  'PRODUCT_COSTS_LOAD_EXPORT_COMPLETE_STATUS'

export const PRODUCT_COSTS_LOAD_IMPORT_PENDING_STATUS =
  'PRODUCT_COSTS_LOAD_IMPORT_PENDING_STATUS'
export const PRODUCT_COSTS_LOAD_IMPORT_COMPLETE =
  'PRODUCT_COSTS_LOAD_IMPORT_COMPLETE'
export const PRODUCT_COSTS_LOAD_IMPORT_RESET = 'PRODUCT_COSTS_LOAD_IMPORT_RESET'

export const PRODUCT_COSTS_LOAD_PER_PAGE_CHANGED =
  'PRODUCT_COSTS_LOAD_PER_PAGE_CHANGED'

export const SET_SELECTED_PRODUCT_COST_PENDING_STATUS =
  'SET_SELECTED_PRODUCT_COST_PENDING_STATUS'
export const SET_SELECTED_PRODUCT_COST_COMPLETE =
  'SET_SELECTED_PRODUCT_COST_COMPLETE'
export const SET_SELECTED_PRODUCT_COST_COMPLETE_STATUS =
  'SET_SELECTED_PRODUCT_COST_COMPLETE_STATUS'

export const SET_PRODUCT_COST_MODAL_IS_OPEN = 'SET_PRODUCT_COST_MODAL_IS_OPEN'
export const SET_IS_SAVING_PRODUCT_COST = 'SET_IS_SAVING_PRODUCT_COST'
export const RECEIVE_CREATE_PRODUCT_COST = 'RECEIVE_CREATE_PRODUCT_COST'
export const RECEIVE_UPDATE_PRODUCT_COST = 'RECEIVE_UPDATE_PRODUCT_COST'
export const UPDATE_ACTIVE_PRODUCT_COST = 'UPDATE_ACTIVE_PRODUCT_COST'
export const REQUEST_PRODUCT_COST_DELETE = 'REQUEST_PRODUCT_COST_DELETE'
export const RECEIVE_PRODUCT_COST_DELETE = 'RECEIVE_PRODUCT_COST_DELETE'
export const SET_PRODUCT_COST_ENABLED_FILTER = 'SET_PRODUCT_COST_ENABLED_FILTER'

export const BULK_UPDATE_PRODUCT_COSTS = 'BULK_UPDATE_PRODUCT_COSTS'

export const RESET_PRODUCT_COSTS = 'RESET_PRODUCT_COSTS'
