export const SALES_ANALYTICS_EXPENSES_LOAD_PENDING_STATUS =
  'SALES_ANALYTICS_EXPENSES_LOAD_PENDING_STATUS'
export const SALES_ANALYTICS_EXPENSES_LOAD_COMPLETE =
  'SALES_ANALYTICS_EXPENSES_LOAD_COMPLETE'
export const SALES_ANALYTICS_EXPENSES_LOAD_COMPLETE_STATUS =
  'SALES_ANALYTICS_EXPENSES_LOAD_COMPLETE_STATUS'

export const SALES_ANALYTICS_EXPENSES_PER_PAGE_CHANGED =
  'SALES_ANALYTICS_EXPENSES_PER_PAGE_CHANGED'

export const SALES_ANALYTICS_EXPENSES_DELETE_EXPENSE =
  'SALES_ANALYTICS_EXPENSES_DELETE_EXPENSE'

export const SALES_ANALYTICS_EXPENSES_TOGGLE_EXPENSE_STATE =
  'SALES_ANALYTICS_EXPENSES_TOGGLE_EXPENSE_STATE'

export const SALES_ANALYTICS_EXPENSES_TOGGLE_EXPENSE_PENDING_STATE =
  'SALES_ANALYTICS_EXPENSES_TOGGLE_EXPENSE_PENDING_STATE'

export const SALES_ANALYTICS_EXPENSES_TOGGLE_EXPENSE_COMPLETE_STATE =
  'SALES_ANALYTICS_EXPENSES_TOGGLE_EXPENSE_COMPLETE_STATE'

export const REQUEST_EXPENSE_RECURRENCES = 'REQUEST_EXPENSE_RECURRENCES'
export const RECEIVE_EXPENSE_RECURRENCES = 'RECEIVE_EXPENSE_RECURRENCES'
export const REQUEST_EXPENSE_RECURRENCES_COMPLETE =
  'REQUEST_EXPENSE_RECURRENCES_COMPLETE'
export const SALES_ANALYTICS_EXPENSES_DELETE_RECURRENCE =
  'SALES_ANALYTICS_EXPENSES_DELETE_RECURRENCE'

export const RESHIPPINGS_LOAD_PENDING_STATUS = 'RESHIPPINGS_LOAD_PENDING_STATUS'
export const RESHIPPINGS_LOAD_COMPLETE = 'RESHIPPINGS_LOAD_COMPLETE'
export const RESHIPPINGS_COMPLETE_STATUS = 'RESHIPPINGS_COMPLETE_STATUS'
export const SET_RESHIPPINGS_PER_PAGE = 'SET_RESHIPPINGS_PER_PAGE'
export const SET_RESHIPPINGS_PAGE = 'SET_RESHIPPINGS_PAGE'
export const SET_RESHIPPINGS_SEARCH_TERM = 'SET_RESHIPPINGS_SEARCH_TERM'
export const SET_RESHIPPINGS_SORT = 'SET_RESHIPPINGS_SORT'

export const SALES_ANALYTICS_EXPENSES_SAVE_EXPENSE_PENDING_STATE =
  'SALES_ANALYTICS_EXPENSES_SAVE_EXPENSE_PENDING_STATE'
export const SALES_ANALYTICS_EXPENSES_SAVE_EXPENSE_COMPLETE =
  'SALES_ANALYTICS_EXPENSES_SAVE_EXPENSE_COMPLETE'
export const SALES_ANALYTICS_EXPENSES_SAVE_EXPENSE_COMPLETE_STATE =
  'SALES_ANALYTICS_EXPENSES_SAVE_EXPENSE_COMPLETE_STATE'

export const SALES_ANALYTICS_EXPENSES_CREATE_EXPENSE_PENDING_STATE =
  'SALES_ANALYTICS_EXPENSES_CREATE_EXPENSE_PENDING_STATE'
export const SALES_ANALYTICS_EXPENSES_CREATE_EXPENSE_COMPLETE =
  'SALES_ANALYTICS_EXPENSES_CREATE_EXPENSE_COMPLETE'
export const SALES_ANALYTICS_EXPENSES_CREATE_EXPENSE_COMPLETE_STATE =
  'SALES_ANALYTICS_EXPENSES_CREATE_EXPENSE_COMPLETE_STATE'

export const SALES_ANALYTICS_EXPENSES_SAVE_RECURRENCE_PENDING =
  'SALES_ANALYTICS_EXPENSES_SAVE_RECURRENCE_PENDING'
export const SALES_ANALYTICS_EXPENSES_SAVE_RECURRENCE_COMPLETE =
  'SALES_ANALYTICS_EXPENSES_SAVE_RECURRENCE_COMPLETE'

export const SALES_ANALYTICS_EXPENSES_PRODUCTS_PENDING_STATE =
  'SALES_ANALYTICS_EXPENSES_PRODUCTS_PENDING_STATE'
export const SALES_ANALYTICS_EXPENSES_PRODUCTS_COMPLETE =
  'SALES_ANALYTICS_EXPENSES_PRODUCTS_COMPLETE'
export const SALES_ANALYTICS_EXPENSES_PRODUCTS_COMPLETE_STATE =
  'SALES_ANALYTICS_EXPENSES_PRODUCTS_COMPLETE_STATE'

export const ADD_EXPENSE_CATEGORY_PENDING_STATE =
  'ADD_EXPENSE_CATEGORY_PENDING_STATE'
export const ADD_EXPENSE_CATEGORY_COMPLETE = 'ADD_EXPENSE_CATEGORY_COMPLETE'
export const ADD_EXPENSE_CATEGORY_COMPLETE_STATE =
  'ADD_EXPENSE_CATEGORY_COMPLETE_STATE'

export const UPLOAD_EXPENSES_CSV_FILE_PENDING_STATE =
  'UPLOAD_EXPENSES_CSV_FILE_PENDING_STATE'
export const UPLOAD_EXPENSES_CSV_FILE_COMPLETE =
  'UPLOAD_EXPENSES_CSV_FILE_COMPLETE'
export const UPLOAD_EXPENSES_CSV_FILE_RESET = 'UPLOAD_EXPENSES_CSV_FILE_RESET'

export const EXPORT_EXPENSES_CSV_FILE_PENDING_STATE =
  'EXPORT_EXPENSES_CSV_FILE_PENDING_STATE'
export const EXPORT_EXPENSES_CSV_FILE_COMPLETE_STATE =
  'EXPORT_EXPENSES_CSV_FILE_COMPLETE_STATE'

export const RESET_EXPENSES = 'RESET_EXPENSES'
