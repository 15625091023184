export const PENDING_SHARE_AND_TRENDS_ACTION = 'PENDING_SHARE_AND_TRENDS_ACTION'
export const RESET_SHARE_AND_TRENDS_ACTION = 'RESET_SHARE_AND_TRENDS_ACTION'
export const SET_SHARE_AND_TRENDS_DATA = 'SET_SHARE_AND_TRENDS_DATA'
export const PENDING_BRAND_PRODUCTS_ACTION = 'PENDING_BRAND_PRODUCTS_ACTION'
export const RESET_BRAND_PRODUCTS_ACTION = 'RESET_BRAND_PRODUCTS_ACTION'
export const SET_BRAND_PRODUCTS_DATA = 'SET_BRAND_PRODUCTS_DATA'
export const PENDING_BRAND_PRODUCT_VARIANTS_ACTION =
  'PENDING_BRAND_PRODUCT_VARIANTS_ACTION'
export const RESET_BRAND_PRODUCT_VARIANTS_ACTION =
  'RESET_BRAND_PRODUCT_VARIANTS_ACTION'
export const SET_BRAND_PRODUCT_VARIANTS_DATA = 'SET_BRAND_PRODUCT_VARIANTS_DATA'
export const SET_SHARE_AND_TRENDS_PREVIOUS_PERIOD_DATES =
  'SET_SHARE_AND_TRENDS_PREVIOUS_PERIOD_DATES'
export const SET_SHARE_AND_TRENDS_RANGE_TYPE = 'SET_SHARE_AND_TRENDS_RANGE_TYPE'
export const SET_SHARE_AND_TRENDS_SEGMENTS = 'SET_SHARE_AND_TRENDS_SEGMENTS'
