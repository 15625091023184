// Keyword Scout Constants
export const PENDING_KEYWORD_ENGINE_ACTION = 'PENDING_KEYWORD_ENGINE_ACTION'
export const COMPLETED_KEYWORD_ENGINE_ACTION = 'COMPLETED_KEYWORD_ENGINE_ACTION'
export const RESET_KEYWORD_ENGINE_ACTION = 'RESET_KEYWORD_ENGINE_ACTION'
export const RESET_KEYWORD_ENGINE = 'RESET_KEYWORD_ENGINE'
export const COMPLETED_GET_RELATED_KEYWORDS = 'COMPLETED_GET_RELATED_KEYWORDS'
export const SET_KEYWORD_ENGINE_DATA = 'SET_KEYWORD_ENGINE_DATA'
export const SET_KEYWORD_ENGINE_FILTER = 'SET_KEYWORD_ENGINE_FILTER'
export const SET_KEYWORD_ENGINE_FILTERS = 'SET_KEYWORD_ENGINE_FILTERS'
export const SET_KEYWORD_ENGINE_COUNTRY = 'SET_KEYWORD_ENGINE_COUNTRY'
export const RESET_KEYWORD_ENGINE_FILTER = 'RESET_KEYWORD_ENGINE_FILTER'
export const RESET_KEYWORD_ENGINE_FEATURE_TERM =
  'RESET_KEYWORD_ENGINE_FEATURE_TERM'
export const TOGGLE_KEYWORD_SCOUT_SAVE_PRESET_MODAL =
  'TOGGLE_KEYWORD_SCOUT_SAVE_PRESET_MODAL'
export const TOGGLE_KEYWORD_SCOUT_LOAD_PRESET_MODAL =
  'TOGGLE_KEYWORD_SCOUT_LOAD_PRESET_MODAL'
export const TOGGLE_KEYWORD_SCOUT_DELETE_PRESET_MODAL =
  'TOGGLE_KEYWORD_SCOUT_DELETE_PRESET_MODAL'
export const SET_KEYWORD_SCOUT_PRESET_NAME = 'SET_KEYWORD_SCOUT_PRESET_NAME'
export const PENDING_KEYWORD_SCOUT_SAVE_PRESET =
  'PENDING_KEYWORD_SCOUT_SAVE_PRESET'
export const COMPLETED_KEYWORD_SCOUT_SAVE_PRESET =
  'COMPLETED_KEYWORD_SCOUT_SAVE_PRESET'
export const PENDING_KEYWORD_SCOUT_LOAD_PRESET =
  'PENDING_KEYWORD_SCOUT_LOAD_PRESET'
export const COMPLETED_KEYWORD_SCOUT_LOAD_PRESET =
  'COMPLETED_KEYWORD_SCOUT_LOAD_PRESET'
export const PENDING_KEYWORD_SCOUT_DELETE_PRESET =
  'PENDING_KEYWORD_SCOUT_DELETE_PRESET'
export const COMPLETED_KEYWORD_SCOUT_DELETE_PRESET =
  'COMPLETED_KEYWORD_SCOUT_DELETE_PRESET'
export const SET_KEYWORD_SCOUT_PRESETS = 'SET_KEYWORD_SCOUT_PRESETS'
export const SET_SELECTED_KEYWORD_SCOUT_PRESET =
  'SET_SELECTED_KEYWORD_SCOUT_PRESET'
export const LOAD_KEYWORD_SCOUT_PRESET = 'LOAD_KEYWORD_SCOUT_PRESET'
