export const WRITE_PERMISSION = 'write'
export const NONE_PERMISSION = 'none'
export const MARKETPLACE_ALL_KEY = 'marketplace.all'

export const PERMISSION_KEYS = {
  AI_FEATURES: 'ai_features',
  COMPETITIVE_INTELLIGENCE: 'competitive_intelligence',
  EXTENSION: 'extension',
  FIND_PRODUCTS: 'find_products',
  KEYWORDS: 'keywords',
  SUPPLIERS: 'suppliers',
  ACADEMY: 'academy',
  ALERTS: 'alerts',
  SALES_ANALYTICS: 'sales_analytics',
  ADVERTISING: 'advertising',
  LAUNCH: 'launch',
  INVENTORY: 'inventory',
  MY_PRODUCTS: 'my_products',
  PUBLIC_API: 'public_api',
  TOOLBOX: 'toolbox',
  MARKET_INSIGHTS: 'marketing_insights',
  SHARE_OF_VOICE: 'share_of_voice',
  SEGMENT_MANAGER: 'segment_manager',
  SHARE_AND_TRENDS: 'share_and_trends',
  PRODUCT_INSIGHTS: 'product_insights'
}

export const GATED_FEATURE_EVENT = 'gated-feature'
export const SET_FEATURE_LIMIT_EVENT = 'set-feature-limit'

export const FEATURE_LIMIT_MAX_HEADER = 'JS-Feature-Limit-Maximum'
export const FEATURE_LIMIT_COUNT_HEADER = 'JS-Feature-Limit-Count'
export const GATED_FEATURE_ERROR_TYPE = 'Gated Feature'
export const ACCESS_LIMIT_TYPE = 'access'
export const TIMEFRAME_LIMIT_TYPE = 'timeframe'
export const QUANTITY_LIMIT_TYPE = 'quantity'
export const TOTAL_SEARCHES_SCOPE = 'totalSearches'
export const MONTHLY_SEARCHES_SCOPE = 'monthlySearches'
export const LISTINGS_SCOPE = 'listings'
export const PRODUCT_TRACKS_SCOPE = 'productTracks'
export const SUPPLIER_TRACKS_SCOPE = 'supplierTracks'
export const SUPPLIER_GROUPS_SCOPE = 'supplierGroups'
export const RANK_TRACKER_KEYWORDS_SCOPE = 'rankTrackerKeywords'
export const REVIEW_AUTOMATION_SCOPE = 'reviewAutomation'

export const GATED_ROUTES = {
  'launch/review-automation': 'reviewAutomation',
  inventory: 'inventoryManager',
  'opportunity-finder': 'opportunityFinder',
  database: 'productDatabase',
  'supplier/?$': 'supplierDatabase',
  'supplier/supplier-tracker': 'supplierTracker',
  'keyword/?$': 'keywordScout',
  'keyword/rank/?$': 'rankTracker',
  'keyword/listings?$': 'listingBuilder',
  'sales-analytics/\\w+': 'salesAnalytics',
  'advertising/\\w+': 'advertising'
}

export const NO_ACCESS_ROUTES = [
  'competitive-intelligence',
  'sales-estimator',
  'listing-grader',
  'review-analysis',
  'database',
  'tracker',
  'opportunity-finder',
  'category-trends',
  'supplier',
  'keyword',
  'launch',
  'sales-analytics',
  'advertising',
  'inventory-forecast',
  'product-costs-and-settings',
  'academy'
]

export const GATED_FEATURES = [
  'advertising',
  'keyword/listings',
  'keyword/rank',
  'keyword/lists',
  'inventory-forecast',
  'supplier/supplier-tracker'
]
