export const PENDING_ASIN_EXPLORER_ACTION = 'PENDING_ASIN_EXPLORER_ACTION'
export const RESET_ASIN_EXPLORER_ACTION = 'RESET_ASIN_EXPLORER_ACTION'
export const SET_ASIN_EXPLORER_DATA = 'SET_ASIN_EXPLORER_DATA'

export const SET_ASIN_EXPLORER_TRACKED_PRODUCTS =
  'SET_ASIN_EXPLORER_TRACKED_PRODUCTS'
export const APPEND_ASIN_EXPLORER_TRACKED_PRODUCTS =
  'APPEND_ASIN_EXPLORER_TRACKED_PRODUCTS'
export const SET_ASIN_EXPLORER_SORTING = 'SET_ASIN_EXPLORER_SORTING'

export const PENDING_ASIN_EXPLORER_TRACK_VARIANTS =
  'PENDING_ASIN_EXPLORER_TRACK_VARIANTS'
export const COMPLETED_ASIN_EXPLORER_TRACK_VARIANTS =
  'COMPLETED_ASIN_EXPLORER_TRACK_VARIANTS'
export const RESET_ASIN_EXPLORER_TRACK_VARIANTS =
  'RESET_ASIN_EXPLORER_TRACK_VARIANTS'

export const COMPLETED_ASIN_EXPLORER_TRACK_ASIN =
  'COMPLETED_ASIN_EXPLORER_TRACK_ASIN'

export const COMPLETED_ASIN_EXPLORER_UNTRACK_ASIN =
  'COMPLETED_ASIN_EXPLORER_UNTRACK_ASIN'

export const SET_ASIN_EXPLORER_PENDING_STATUS =
  'SET_ASIN_EXPLORER_PENDING_STATUS'

export const PENDING_ASIN_EXPLORER_CSV_IMPORT =
  'PENDING_ASIN_EXPLORER_CSV_IMPORT'

export const COMPLETED_ASIN_EXPLORER_CSV_IMPORT =
  'COMPLETED_ASIN_EXPLORER_CSV_IMPORT'
